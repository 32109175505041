.scrollable-content {
    max-height: 600px; 
    overflow-y: auto;
    overflow-x: hidden; 
    width: 100%; 

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  