.bg-gradient-custom {
  background: linear-gradient(
    90deg,
    rgba(29, 140, 248, 1) 0%,
    rgba(29, 140, 248, 0.8) 100%
  );
  color: white;
}

.table-hover tbody tr:hover {
  background-color: rgba(29, 140, 248, 0.1);
}

.btn-info {
  background-color: #1d8cf8;
  border-color: #1d8cf8;
}

.btn-info:hover {
  background-color: #1177e9;
  border-color: #1177e9;
}

.btn-warning {
  background-color: #ff8d72;
  border-color: #ff8d72;
}

.btn-warning:hover {
  background-color: #fc7252;
  border-color: #fc7252;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.icon-action {
  cursor: pointer;
  color: #17a2b8; /* Bootstrap's info color for a consistent look */
  margin-right: 15px; /* Ensures spacing between icons */
}

/* Hover effect for icons to provide visual feedback */
.icon-action:hover {
  color: #138496;
}

/* Optional: Adjust the last icon's margin to remove extra spacing */
.icon-action:last-child {
  margin-right: 0;
}

/* search css component */
.search-container {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

/* card title */
.job-details-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.job-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.job-title h2 {
  margin: 0;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}

.experience-badge {
  background: linear-gradient(45deg, #007bff, #007bff);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.job-description h4 {
  margin-top: 0;
  color: #666;
  display: flex;
  align-items: center;
  gap: 5px;
}

.job-description p {
  color: #555;
  line-height: 1.6;
  text-align: justify;
}

.job-footer {
  margin-top: 20px;
  color: #777;
  text-align: center;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

/* Optional: Include this if you want icons in your design */
i {
  color: #007bff;
}
