/* ViewAllMarkWorkers.scss */
.scrollable-section {
    max-height: 500px;
    overflow-y: auto;
    padding: 15px;
  }
  
  /* Style the scrollbar (for webkit browsers) */
  .scrollable-section::-webkit-scrollbar {
    width: 8px;
  }
  
  .scrollable-section::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .scrollable-section::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .scrollable-section::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  